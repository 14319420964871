<template>
  <div class="bills">
    <section>
      <b-field grouped group-multiline position="is-right" class="p-2">
        <b-tooltip label="Refresh">
          <button
            class="button is-light mb-2"
            :loading="isLoading"
            @click="fetchInvoices"
          >
            <b-icon icon="refresh" size="is-small"> </b-icon>
          </button>
        </b-tooltip>
      </b-field>
      <b-tabs :animated="animated">
        <b-tab-item label="Pending">
          <BillTable :bills="pendingInvoices" />
        </b-tab-item>

        <b-tab-item label="Paid">
          <BillTable :bills="paidInvoices" />
        </b-tab-item>
      </b-tabs>
    </section>
  </div>
</template>

<script>
import BillTable from "../../components/ui/BillTable.vue";

export default {
  components: {
    BillTable,
  },
  data() {
    return {
      data: [],
      isLoading: false,
      animated: false,
      checkboxPosition: "left",
      checkboxType: "is-primary",
      checkedRows: [],
      columns: [
        {
          field: "id",
          label: "ID",
          width: "40",
          numeric: true,
        },
        {
          field: "job_title",
          label: "Job Title",
        },
        {
          field: "job_category",
          label: "Job Category",
        },
        {
          field: "job_location",
          label: "Job Location",
        },
        {
          field: "date",
          label: "Posted On",
          centered: true,
        },
        {
          field: "views",
          label: "Job Views",
        },
      ],
    };
  },
  computed: {
    activeEmployer() {
      return this.$store.getters["employers/getEmployer"];
    },
    pendingInvoices() {
      return this.$store.getters["invoices/getPendingInvoices"];
    },
    paidInvoices() {
      return this.$store.getters["invoices/getPaidInvoices"];
    },
  },
  methods: {
    fetchInvoices() {
      this.isLoading = true;
      this.$store
        .dispatch("invoices/fetchInvoices", this.activeEmployer.id)
        .then(() => {
          this.isLoading = false;
        });
    },
  },
  created() {
    //
  },
};
</script>

<style></style>
