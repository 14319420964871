<template>
  <b-table
    :data="isEmpty ? [] : bills"
    :paginated="isPaginated"
    :per-page="perPage"
    :current-page.sync="currentPage"
    :pagination-rounded="isPaginationRounded"
    :checked-rows.sync="checkedRows"
    :checkbox-position="checkboxPosition"
    :checkbox-type="checkboxType"
  >
    <b-table-column
      field="invoice_no"
      label="Invoice Number"
      width="30"
      sortable
      v-slot="bill"
    >
      <template>
        <router-link
          class="is-ghost"
          :to="{ name: 'EmployerBillDetail', params: { id: bill.row.id } }"
        >
          {{ bill.row.invoice_no }}
        </router-link>
      </template>
    </b-table-column>
    <b-table-column
      field="invoice_date"
      label="Invoice Date"
      width="30"
      sortable
      v-slot="bill"
    >
      <template v-if="bill.row.invoice_date">
        {{ formatDate(bill.row.invoice_date) }}
      </template>
      <template v-else> Not date</template>
    </b-table-column>
    <b-table-column
      field="description"
      label="Description"
      width="200"
      sortable
      v-slot="bill"
    >
      <template>
        {{ bill.row.description }}
      </template>
    </b-table-column>
    <b-table-column
      field="amount"
      label="Amount"
      width="50"
      sortable
      v-slot="bill"
    >
      <template>
        {{ formatNumber(bill.row.amount) }}
      </template>
    </b-table-column>
    <b-table-column
      field="due_date"
      label="Due Date"
      width="50"
      sortable
      v-slot="bill"
    >
      <template v-if="bill.row.due_date">
        {{ formatDate(bill.row.due_date) }}
      </template>
      <template v-else> None</template>
    </b-table-column>
    <b-table-column
      field="status"
      label="Payment status"
      width="50"
      sortable
      v-slot="bill"
    >
      <template>
        {{
          bill.row.status === "1"
            ? "Pending"
            : bill.row.status === "2"
            ? "In Review"
            : "Paid"
        }}
      </template>
    </b-table-column>
    <template #bottom-left> <b>Total items</b>: {{ bills.length }} </template>
    <template #empty>
      <div class="has-text-centered">We have nothing to show here.</div>
    </template>
  </b-table>
</template>

<script>
export default {
  //component information
  name: "BillTable",
  components: {},
  props: {
    bills: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      isEmpty: false,
      isPaginated: true,
      isPaginationRounded: false,
      paginationPosition: "bottom",
      currentPage: 1,
      perPage: 10,
      checkboxPosition: "left",
      checkboxType: "is-primary",
      checkedRows: [],
    };
  },
  methods: {
    //format date to day/month/year
    formatDate(date) {
      return new Date(date).toLocaleDateString("en-GB");
    },
    formatNumber(number) {
      // add thoursand separator
      return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
  },
};
</script>
